<template>
  <v-container id="transaction-list" tag="section" fluid grid-list-md>
    <base-material-card icon="mdi-bank-transfer" :title="$t('transactions.list')">
      <v-data-table :headers="headers" :items="items" mobile-breakpoint="500" :options.sync="options"
        :server-items-length="total" :loading="loading" multi-sort sort-by="created_at" sort-desc :footer-props="{
      'items-per-page-options': [10, 20, 50],
    }">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-select v-if="!$route.params.user_id" v-model="user_id" class="search-field" item-value="id"
              :label="$t('transactions.user')" hide-details :items="forSelect(meta.users)"
              @change="changeOutput"></v-select>
            <v-select v-model="client_id" class="search-field" item-value="id" :label="$t('transactions.client_id')"
              hide-details :items="forSelect(meta.clients)" @change="changeOutput"></v-select>
            <v-text-field @change="changeOutput" class="search-field" v-model="search" append-icon="search"
              :label="$t('search')" single-line hide-details></v-text-field>
            <v-select v-model="direction" class="search-field" item-value="id" :label="$t('transactions.direction')"
              hide-details :items="directions" @change="changeOutput"></v-select>
            <v-select v-model="status" class="search-field" item-value="id" :label="$t('transactions.status')"
              hide-details :items="statuses" @change="changeOutput"></v-select>
            <v-select v-model="gateway_id" class="search-field" item-value="id" :label="$t('transactions.gateway')"
              hide-details :items="forSelect(meta.gateways)" @change="changeOutput"></v-select>
            <v-select v-model="currency" class="search-field" item-value="id" :label="$t('transactions.currency')"
              hide-details :items="currencies" @change="changeOutput"></v-select>
            <v-spacer />
            <v-btn v-if="user_id" color="primary" dark class="mb-2" @click="addPayment">
              <v-icon left>mdi-cash-plus</v-icon>
              {{ $t("transactions.add") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ moment(item.created_at).format("lll") }}
        </template>
        <template v-slot:item.is_payout="{ item }">
          {{ $t("transactions.is_payout." + item.is_payout) }}
        </template>
        <template v-slot:item.transaction_id="{ item }">
          {{ item.transaction_id }} <span v-if="item.capture">({{ item.capture }})</span>
        </template>
        <template v-slot:item.status="{ item }">
          {{ $t("payment.statuses." + item.status.toUpperCase()) }}
        </template>
        <template v-slot:item.gateway="{ item }">
          {{ item.gateway }} <br />
          <i v-if="item.gateway_id == 8">
            {{ item.batch_id }}
            <v-icon v-if="item.sepa_file" tag="a" download :title="$t('dd.download')"
              @click="onDownloaded(item)">mdi-download</v-icon>
          </i>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.gateway_id == 8 && canEditDD" small class="mr-2" @click="onEditDD(item)"
            :title="$t('bank transfers.dd details')">mdi-credit-card-edit-outline</v-icon>
          <v-icon v-else-if="item.gateway_id == 8" small class="mr-2" @click="viewDetails(item)"
            :title="$t('bank transfers.dd details')">mdi-credit-card-outline</v-icon>
          <v-icon v-if="canEditStatus" small class="mr-2" @click="onEditStatus(item)"
            :title="$t('transactions.edit_status')">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="syncTransaction(item)"
            :title="$t('transactions.sync')">mdi-refresh</v-icon>
          <v-icon v-if="item.custom_note" small class="mr-2" :title="item.custom_note">mdi-note</v-icon>
        </template>
      </v-data-table>
      <details-dialog ref="detailsDialog" title="Details"></details-dialog>
      <direct-debit-form v-if="canEditDD" @itemSaved="onSavedDD" ref="editFormDD"></direct-debit-form>
      <edit-status-form v-if="canEditStatus" @itemSaved="onSavedStatus" ref="editStatusForm"></edit-status-form>
      <add-payment-form v-if="canEditStatus && user_id" @itemSaved="onNewPayment" :user_id="user_id"
        ref="addPaymentForm"></add-payment-form>
    </base-material-card>
  </v-container>
</template>

<script>
import moment from "moment";
import crudMixin from "mixins/crudTable";
import DetailsDialog from "@/components/crud/DetailsDialog";
import DirectDebitForm from "./DirectDebitForm";
import EditStatusForm from "./EditStatusForm";
import AddPaymentForm from "./AddPaymentForm";

export default {
  mixins: [crudMixin],
  components: {
    DetailsDialog,
    DirectDebitForm,
    EditStatusForm,
    AddPaymentForm,
  },
  data() {
    return {
      search: "",
      apiQuery: "/transactions/",
      headers: [
        { text: this.$t("transactions.user"), align: "start", value: "user" },
        { text: this.$t("transactions.client_id"), value: "client" },
        { text: this.$t("transactions.time"), value: "created_at" },
        {
          text: this.$t("transactions.Transaction Id"),
          value: "transaction_id",
        },
        { text: this.$t("transactions.status"), value: "status" },
        { text: this.$t("transactions.purpose"), value: "purpose" },
        { text: this.$t("transactions.gateway"), value: "gateway" },
        { text: this.$t("transactions.direction"), value: "is_payout" },
        {
          text: this.$t("transactions.amount"),
          value: "amount",
          align: "right",
        },
        { text: this.$t("transactions.currency"), value: "currency" },
        {
          text: this.$t("transactions.Actions"),
          value: "actions",
          sortable: false,
        },
      ],
      status: "ALL",
      statuses: [
        {
          id: "ALL",
          text: this.$t("payment.statuses.ALL"),
        },
        {
          id: "COMPLETED",
          text: this.$t("payment.statuses.COMPLETED"),
        },
        {
          id: "PENDING",
          text: this.$t("payment.statuses.PENDING"),
        },
        {
          id: "REQUESTED",
          text: this.$t("payment.statuses.REQUESTED"),
        },
        {
          id: "CANCELLED",
          text: this.$t("payment.statuses.CANCELLED"),
        },
        {
          id: "FAILED",
          text: this.$t("payment.statuses.FAILED"),
        },
        {
          id: "REFUNDED",
          text: this.$t("payment.statuses.REFUNDED"),
        },
        {
          id: "ERROR",
          text: this.$t("payment.statuses.ERROR"),
        },
      ],
      directions: [
        {
          id: "",
          text: this.$t("transactions.is_payout.ALL"),
        },
        {
          id: "0",
          text: this.$t("transactions.is_payout.0"),
        },
        {
          id: "1",
          text: this.$t("transactions.is_payout.1"),
        },
      ],
      loading: true,
      direction: "",
      currency: "EUR",
      currencies: [
        {
          id: "EUR",
          text: "Euro",
        },
      ],
      client_id: null,
      clients: [],
      gateway_id: null,
      gateways: [],
      user_id: null,
      users: [],
    };
  },
  mounted() {
    this.user_id = this.options.user_id = this.$route.params.user_id
      ? this.$route.params.user_id
      : null;
    this.get(
      "/transactions/meta?" +
      (this.options.user_id ? "user_id=" + this.options.user_id : "")
    ).then((data) => {
      this.meta = data;
    });
    if (this.$route.query.status) {
      this.status = this.$route.query.status.toUpperCase();
      this.$set(this.options, "status", this.status);
    }
  },
  computed: {
    canEditDD() {
      return this.$store.getters.userhasPermission("update bank transfers");
    },
    canEditStatus() {
      return this.$store.getters.userhasPermission("edit transactions");
    },
  },
  methods: {
    changeOutput() {
      this.$set(this.options, "search", this.search);
      this.$set(this.options, "status", this.status);
      this.$set(this.options, "is_payout", this.direction);
      this.$set(this.options, "currency", this.currency);
      this.$set(this.options, "client_id", this.client_id);
      this.$set(this.options, "user_id", this.user_id);
      this.$set(this.options, "gateway_id", this.gateway_id);
      this.options.page = 1;
    },
    moment,
    viewDetails(item) {
      this.$refs.detailsDialog.viewDetails("/transactions/dd/" + item.id);
    },
    onEditDD(item) {
      this.$refs.editFormDD.editItem(item, this.items.indexOf(item));
    },
    onSavedDD() {
      window.setTimeout(() => {
        this.$refs.editFormDD.savedItem = null;
      }, 1500);
    },
    onEditStatus(item) {
      this.$refs.editStatusForm.editItem(item, this.items.indexOf(item));
    },
    onSavedStatus(item, index) {
      this.items[index].status = item.status;
      this.items[index].custom_note = item.custom_note;
      this.$refs.editStatusForm.closeForm();
    },
    onDownloaded(item) {
      this.download("/sepa/files/" + item.sepa_file, item.sepa_filename)
        .then(() => {
          item.downloaded = 1;
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    syncTransaction(item) {
      this.$refs.detailsDialog.viewDetails(
        "/transactions/sync/" + item.id,
        this.$t("transactions.sync") + " " + item.transaction_id
      );
    },
    addPayment() {
      this.$refs.addPaymentForm.addItem();
    },
    onNewPayment(item) {
      this.total++;
      item.isNew = true;
      this.items.push(item);
      window.setTimeout(() => {
        this.$refs.addPaymentForm.clearForm();
      }, 1500);
    },
  },
};
</script>
<style scoped>
.search-field {
  margin-right: 10px;
}

.break-style {
  word-break: break-word;
  max-width: 7.5rem;
}
</style>
