<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container v-if="form">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.amount"
                  :label="$t('transactions.amount')"
                  type="number"
                  step="0.01"
                  prepend-icon="mdi-currency-eur"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.purpose"
                  :label="$t('transactions.purpose')"
                  prepend-icon="mdi-pencil"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.custom_note"
                  :label="$t('transactions.note')"
                  prepend-icon="mdi-pencil"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="form">
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >The transaction {{ savedItem.transaction_id }} was saved with
        success!</v-alert
      >
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    user_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      apiQuery: "/transactions/",
    };
  },
  computed: {
    formTitle() {
      return this.$t("transactions.add");
    },
  },
  methods: {
    clearForm() {
      this.errorMessage = null;
      this.serverErrors = null;
      this.editedItem = null;
      this.savedItem = null;
      this.form = Object.assign({}, this.defaultItem);
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.form.currency = "EUR";
      this.form.user_id = this.user_id;
    },
  },
};
</script>
