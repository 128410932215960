<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container v-if="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.status"
                  item-value="id"
                  :label="$t('transactions.status')"
                  hide-details
                  :items="statuses"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.custom_note"
                  :label="$t('transactions.note')"
                  prepend-icon="mdi-pencil"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="form">
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >The transaction {{ savedItem.id }} was saved with success!</v-alert
      >
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {},
  data() {
    return {
      apiQuery: "/transactions/",
      statuses: [
        {
          id: "COMPLETED",
          text: this.$t("payment.statuses.COMPLETED"),
        },
        {
          id: "PENDING",
          text: this.$t("payment.statuses.PENDING"),
        },
        {
          id: "REQUESTED",
          text: this.$t("payment.statuses.REQUESTED"),
        },
        {
          id: "CANCELLED",
          text: this.$t("payment.statuses.CANCELLED"),
        },
        {
          id: "FAILED",
          text: this.$t("payment.statuses.FAILED"),
        },
        {
          id: "REFUNDED",
          text: this.$t("payment.statuses.REFUNDED"),
        },
        {
          id: "ERROR",
          text: this.$t("payment.statuses.ERROR"),
        },
      ],
    };
  },

  computed: {
    formTitle() {
      return this.$t("transactions.edit_status");
    },
  },
  methods: {},
};
</script>
