<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container v-if="form">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="form.company"
                  :label="$t('user.Company')"
                  :rules="rules.company"
                  prepend-icon="mdi-domain"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.fname"
                  :label="$t('user.fname')"
                  :rules="rules.fname"
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.lname"
                  :label="$t('user.lname')"
                  :rules="rules.lname"
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.iban"
                  :label="$t('banks.table.iban')"
                  :rules="rules.iban"
                  prepend-icon="mdi-credit-card"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.bic"
                  :label="$t('banks.table.bic')"
                  :rules="rules.bic"
                  prepend-icon="mdi-credit-card"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.bank"
                  :label="$t('banks.table.bank_name')"
                  :rules="rules.bank"
                  prepend-icon="mdi-bank"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="form">
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >The transfer {{ savedItem.id }} was saved with success!</v-alert
      >
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {},
  data() {
    return {
      apiQuery: "/transactions/dd/",
      rules: {
        company: [
          () =>
            this.serverErrors && this.serverErrors.company
              ? this.$t("banks.errors." + this.serverErrors.company[0])
              : true,
        ],
        fname: [
          () =>
            this.serverErrors && this.serverErrors.fname
              ? this.$t("banks.errors." + this.serverErrors.fname[0])
              : true,
        ],
        lname: [
          () =>
            this.serverErrors && this.serverErrors.lname
              ? this.$t("banks.errors." + this.serverErrors.lname[0])
              : true,
        ],
        bank: [
          () =>
            this.serverErrors && this.serverErrors.bank
              ? this.$t("banks.errors." + this.serverErrors.bank[0])
              : true,
        ],
        bic: [
          (v) => !!v || "Required.",
          (v) => {
            return !v || validate.bic.test(v) ? true : "Invalid bic";
          },
          () =>
            this.serverErrors && this.serverErrors.bic
              ? this.$t("banks.errors." + this.serverErrors.bic[0])
              : true,
        ],
        iban: [
          (v) => !!v || "Required.",
          (v) => {
            return !v || validate.iban.test(v) ? true : "Invalid iban";
          },
          () =>
            this.serverErrors && this.serverErrors.iban
              ? this.$t("banks.errors." + this.serverErrors.iban[0])
              : true,
        ],
      },
    };
  },
  watch: {
    search(val) {
      val && val !== this.form.address && this.querySelections(val);
    },
  },
  computed: {
    formTitle() {
      return this.$t("bank transfers.dd details");
    },
  },
  methods: {
    editItem(item, index) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.editedItem = item;
      this.editedIndex = index;
      this.loading = true;
      this.form = null;
      this.get(this.apiQuery + item.id)
        .then((data) => {
          data.id = item.id;
          this.form = Object.assign({}, data);
          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = error.error || error.message;
          this.$emit("serverError", error);
          this.loading = false;
        });
      this.showFormDialog = true;
    },
  },
};
</script>
